<template>
  <form class="form t-form animated fadeIn" @submit.prevent="logMeIn"  >
      <div class ="col-md-12 row">
        <div class="group col-md-6">
            <label class="col-md-12 " style="margin-top:10px;text-align: left !important;float:left !important;">E-mail</label>
            <input class="col-md-12" v-model="login.email" name="email" >
        </div>
        <br>
        <div class="group col-md-6">
            <label class="col-md-12" style="margin-top:10px;text-align: left !important;float:left !important;">Contraseña</label>
            <input class="col-md-12" v-model="login.password" name="password" type="password" >
        </div>
      </div>
      <br>
      <div class="group col-md-12">
          <button class="btn btn-lg btn-primary full submit"
              :disable="isLoading == true">
              Iniciar sesión
          </button>

      </div>
      <div class="group col-md-12">
          <br>
          <p class="forgot-link" style="max-width:100%">
              <a href="/forgot_password" class="">Olvidaste tu contraseña? </a>
          </p>
      </div>
      <br>
      <br>
  </form>
</template>
<script>
export default {
  data() {
    return {
      login: {
          email: null,
          password: null,
      },
      isLoading: false,
    }
  },
  methods:{
    logMeIn(){
        this.isLoading = true;
        axios.post("/login", this.login)
            .then((res) => {
                this.isLoading = false;
                Swal.fire({
                  title: "Felicidades!",
                  text: "Has iniciado sesion correctamente.",
                  icon: "success",
                });
                window.setTimeout(() => {
                    window.location.assign("/contact-data");
                }, 2500);
            })
            .catch((err) => {
                this.isLoading = false;
                let errs = err.response.data.errors
                if (errs.constructor === Object){
                  Swal.fire({
                    title: "Error!",
                    text:  errs[Object.keys(errs)[0]][0],
                    icon: "error",
                  });
                }else{
                  Swal.fire({
                    title: "Error!",
                    text:  errs,
                    icon: "error",
                  });
                }

            });
    },
  }
}
</script>
