<template>
            <form class="form t-form animated fadeIn" @submit.prevent="signUp()" >
                <div class ="row col-md-12">
                  <div class="group col-md-6">
                      <label class="col-md-12" style="margin-top:10px;text-align: left !important;float:left !important;">Nombre </label>
                      <input class="col-md-12" name="nombre" v-model="candidate.name" placeholder="">
                  </div>
                  <div class="group col-md-6" >
                      <label class="col-md-12" style="margin-top:10px;text-align: left !important;float:left !important;">Apellido Paterno</label>
                      <input class="col-md-12" name="apellido_paterno" v-model="candidate.apellido_paterno" placeholder="">
                  </div>

                  <div class="group col-md-6">
                      <label class="col-md-12" style="margin-top:10px;text-align: left !important;float:left !important;">Apellido Materno</label>
                      <input class="col-md-12" name="apellido_materno" v-model="candidate.apellido_materno" placeholder="">
                  </div>
                  <div class="group col-md-6">
                      <label class="col-md-12" style="margin-top:10px;text-align: left !important;float:left !important;">E-mail</label>
                      <input class="col-md-12" type="email" name="email" v-model="candidate.email" placeholder="" >
                  </div>

                  <div class="group col-md-6">
                      <label class="col-md-12" style="margin-top:10px;text-align: left !important;float:left !important;">Contraseña</label>
                      <input class="col-md-12" name="contraseña" v-model="candidate.password" type="password" placeholder="">
                  </div>
                </div><br>

                <div class="form-group" style="margin-bottom:15px">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="terminos-condiciones" required>
                        <label class="form-check-label" for="terminos-condiciones">
                            Acepto los <a target="_blank" href="/terminos-y-condiciones">términos y condiciones</a>
                        </label>
                    </div>
                </div>
                <div class="group">
                    <button class="btn btn-lg btn-primary full submit"
                        :disabled="isLoading == true">
                        Registrarme
                    </button>

                </div>
            </form>
</template>
<script>
export default {
  props: ['propCurp'],
  data() {
    return {
      candidate: {
          name: null,
          apellido_materno: null,
          apellido_paterno: null,
          password: null,
          email: null,
          curp:null,
      },
      isLoading: false,
    }
  },
  methods:{
    signUp() {
        this.isLoading = true;
        this.candidate.curp = this.propCurp;
        axios.post("/register", this.candidate)
            .then((res) => {
                this.isLoading = false;
                Swal.fire({
                  title: "Felicidades!",
                  text: "Tu cuenta se ha registrado con éxito.",
                  icon: "success",
                });
                window.setTimeout(() => {
                    window.location.assign("/contact-data");
                }, 2500);
            })
            .catch((err) => {
                this.isLoading = false;
                let errs = err.response.data.errors
                if (errs.constructor === Object){
                  Swal.fire({
                    title: "Error!",
                    text:  errs[Object.keys(errs)[0]][0],
                    icon: "error",
                  });
                }else{
                  Swal.fire({
                    title: "Error!",
                    text:  errs,
                    icon: "error",
                  });
                }
            });
    },
  }
}
</script>
