require('./bootstrap');
import Swal from 'sweetalert2';
window.Swal = Swal;

window.Vue = require('vue');

Vue.component('form-login', require('./components/FormLoginComponent.vue').default);
Vue.component('form-signup', require('./components/FormSignUpComponent.vue').default);

const app = new Vue({
    el: '#auth',
});
